import { dailySaleAPI, dailyRadialDataAPI } from "../Api/salepage";

export function dailySale(data = {}) {
    return new Promise((resolve, reject) => {
        dailySaleAPI().then(res => {
            return resolve(res);
        }).catch(err => {
            return reject(err);
        })
    })
}
export function dailyRadialData(data = {}) {
    return new Promise((resolve, reject) => {
        dailyRadialDataAPI().then(res => {
            return resolve(res);
        }).catch(err => {
            return reject(err);
        })
    })
}