import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FlapDisplay, Presets } from "react-split-flap-effect";
import { getDateData } from "../../Actions/datepage";
import { w3cwebsocket, WebSocketClient } from "websocket";
import { socketURL } from "../../constant";
import Clock from "react-digital-clock";
import moment from "moment";

export const ws = new w3cwebsocket(socketURL);

function DatePage({ characterSet }) {
  // const [intervalId, setIntervalID] = useState(null);
  // const [day, setDay] = useState(0);
  const [time, setTime] = useState(new Date());
  const [data, setData] = useState({
    count_data: {
      day: {
        count: 0,
        date: "2021-08-16 00:00:00",
      },
      week: {
        count: 0,
        date: "2021-08-09 00:00:00",
      },
      month: {
        count: 0,
        date: "2021-07-16 00:00:00",
      },
      quarter: {
        count: 0,
        date: "2021-05-16 00:00:00",
      },
    },
    message: "Test Message Saved",
  });


  useEffect(() => {
    let reload = setInterval(() => {
      window.location.reload();
    }, 1800000);
    return () => {
      clearInterval(reload)
    }
  }, [])

  useEffect(() => {
    let interval = setInterval(() => {
      currentTime();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const currentTime = () => {
    setTime(new Date());
  };

  useEffect(() => {
    // Socket implementation
    if (ws.readyState == ws.OPEN) {
      console.log("Web socket is open");
    }

    ws.onopen = () => {
      console.log("WebSocket Client Connected");
    };
  }, []);

  useEffect(() => {
    //API for getting data from the
    getDateData()
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log("Err are", err);
      });
  }, []);

  useEffect(() => {
    ws.onmessage = function (data) {
      if (data.type == "message") {
        let { data: targetData = "{}" } = data;
        let tracker_data = (targetData && JSON.parse(targetData)) || {};
        let { transactionCounter = {} } = tracker_data || {};
        console.log("HOMMMMMMMMMMMMMMMMMM", transactionCounter);
        // console.log("Retrieve data");
        setData({
          count_data: { ...transactionCounter },
          message: transactionCounter.message || "",
        });
      }
    };
  }, []);

  let { count_data = {} } = data;
  let weekCount =
    (count_data.week &&
      count_data.week.count &&
      count_data.week.count.toString()) ||
    "0";
  let dayCount =
    (count_data.day &&
      count_data.day.count &&
      count_data.day.count.toString()) ||
    "0";
  let monthCount =
    (count_data.month &&
      count_data.month.count &&
      count_data.month.count.toString()) ||
    "0";
  let quarterCount =
    (count_data.quarter &&
      count_data.quarter.count &&
      count_data.quarter.count.toString()) ||
    "0";
  let halfYearCount =
    (count_data.halfyearly &&
      count_data.halfyearly.count &&
      count_data.halfyearly.count.toString()) ||
    "0";
  let yearlyCount =
    (count_data.yearly &&
      count_data.yearly.count &&
      count_data.yearly.count.toString()) ||
    "0";

  return (
    <div className="cus-main-container">
      <div className="cus-inner-container">
        <div className="full-width">
          <div className="page-heading">
            <h2>Transaction Counter</h2>
          </div>
        </div>
        <div className="cus-time-sec">
          <h3>
            {/* <Clock /> */}
            {moment().format("h:mm:ss a")}
          </h3>
        </div>
        <div className="full-width cus-middle-sec">
          <div className="third-width cus-grid-rep">
            <div className="part">
              <div className="grid-heading">
                <Typography variant="h3">Day</Typography>
              </div>
              <div>
                <FlapDisplay
                  className="XL light lightBordered"
                  chars={Presets.NUM + ",!"}
                  length={dayCount.length}
                  timing={50}
                  value={dayCount}
                />
              </div>
            </div>
          </div>
          <div className="third-width cus-grid-rep">
            <div className="part">
              <div className="grid-heading">
                <Typography variant="h3">Week</Typography>
              </div>
              <div>
                <FlapDisplay
                  className="XL light lightBordered"
                  chars={Presets.NUM + ",!"}
                  length={weekCount.length}
                  timing={50}
                  value={weekCount}
                />
              </div>
            </div>
          </div>
          <div className="third-width cus-grid-rep">
            <div className="part">
              <div className="grid-heading">
                <Typography variant="h3">Month</Typography>
              </div>
              <div>
                <FlapDisplay
                  className="XL light lightBordered"
                  chars={Presets.NUM + ",!"}
                  length={monthCount.length}
                  timing={50}
                  value={monthCount}
                />
              </div>
            </div>
          </div>
          <div className="third-width cus-grid-rep">
            <div className="part">
              <div className="grid-heading">
                <Typography variant="h3">Quarter</Typography>
              </div>
              <div>
                <FlapDisplay
                  className="XL light lightBordered"
                  chars={Presets.NUM + ",!"}
                  length={quarterCount.length}
                  timing={50}
                  value={quarterCount}
                />
              </div>
            </div>
          </div>
          <div className="third-width cus-grid-rep">
            <div className="part">
              <div className="grid-heading">
                <Typography variant="h3">Half Year</Typography>
              </div>
              <div>
                <FlapDisplay
                  className="XL light lightBordered"
                  chars={Presets.NUM + ",!"}
                  length={halfYearCount.length}
                  timing={50}
                  value={halfYearCount}
                />
              </div>
            </div>
          </div>
          <div className="third-width cus-grid-rep">
            <div className="part">
              <div className="grid-heading">
                <Typography variant="h3">Yearly</Typography>
              </div>
              <div>
                <FlapDisplay
                  className="XL light lightBordered"
                  chars={Presets.NUM + ",!"}
                  length={yearlyCount.length}
                  timing={50}
                  value={yearlyCount}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="full-width">
          <p className="date-message">{data.message || ""}</p>
        </div>
      </div>
    </div>
  );
}

export default DatePage;
