import { Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { radialChartOptions, barChartOption } from "./chartData";
import WhiteCircleChart from "./WhiteCircleChart";
import WhiteCircleSecondChart from "./WhiteCircleSecondChart";
import { socketURL } from "../../constant";
import { dailySale, dailyRadialData } from "../../Actions/salepage";
import moment from "moment";
import { w3cwebsocket, WebSocketClient } from "websocket";

export const ws = new w3cwebsocket(socketURL);

function HomePage() {
  const [radialSeries, setRadialSeries] = useState([0, 0, 0, 0, 0, 0]);
  const [radialoption, setRadialOption] = useState({ ...radialChartOptions });
  const [removedDays, setRemovedDays] = useState([]);
  const [radialData, setRadialData] = useState({});
  const [barSeries, setBarSeries] = useState([
    { data: [...new Array(30).fill(0)] },
  ]);
  const [weekDays, setWeekDays] = useState([]);
  const [barOption, setBarOption] = useState({ ...barChartOption });
  const [currentMonth, setCurrentMonth] = useState(null);

  useEffect(() => {
    let reload = setInterval(() => {
      window.location.reload();
    }, 1800000);
    return () => {
      clearInterval(reload)
    }
  }, [])

  useEffect(() => {
    // Socket implementation
    if (ws.readyState == ws.OPEN) {
      console.log("Web socket is open");
    }

    ws.onopen = () => {
      console.log("WebSocket Client Connected");
    };

    setCurrentMonth(moment().format("MMMM"));
  }, []);

  useEffect(() => {
    ws.onmessage = function (data) {
      if (data.type == "message") {
        console.log("ASDDDDDDDDDD");
        let { data: targetData = "{}" } = data;
        let tracker_data = (targetData && JSON.parse(targetData)) || {};
        let {
          everyDaySale = [],
          current_month_start_date = new Date(),
          targetTracker = {},
        } = tracker_data;

        setGraphData(targetTracker);
        setGraphSeriesData(current_month_start_date, everyDaySale);
      }
    };

    setWeekDays(getWeekDays());
  }, []);

  const getWeekDays = () => {
    var d = new Date();
    var getTot = daysInMonth(d.getMonth(), d.getFullYear()); //Get total days in a month
    var weekDays = new Array(); //Declaring array for inserting Saturdays

    for (var i = 1; i <= getTot; i++) {
      //looping through days in month
      var newDate = new Date(d.getFullYear(), d.getMonth(), i);
      if (newDate.getDay() !== 0 && newDate.getDay() !== 6) {
        //if Sunday ,Sunday
        weekDays.push(i);
      }
    }

    return weekDays;

    function daysInMonth() {
      let month = moment().format("MM");
      let year = moment().format("YYYY");
      return new Date(year, month, 0).getDate();
    }
  };

  const createCategories = () => {
    return getWeekDays();
  };

  useEffect(() => {
    //API for getting data from the
    dailySale().then((res) => {
      let { sale_amount_data = [], current_month_start_date = new Date() } =
        res;
      setGraphSeriesData(current_month_start_date, sale_amount_data);
    });

    dailyRadialData()
      .then((res) => {
        let { tracker_data = {} } = res;
        setGraphData(tracker_data);
      })
      .catch((err) => {});
  }, []);

  const setGraphSeriesData = (current_month_start_date, sale_amount_data) => {
    setCurrentMonth(current_month_start_date);
    let daysRemoved = [];
    let days = createCategories();

    let updatedBarSeries = [];
    sale_amount_data.length &&
      sale_amount_data.map((item, index) => {
        let dayIndex = index + 1;
        let isDayInserted = days.indexOf(dayIndex);

        if (isDayInserted < 0) {
          daysRemoved.push(dayIndex);
        } else {
          return updatedBarSeries.push(item);
        }
      });
    setRemovedDays(daysRemoved);
    setBarSeries([{ data: updatedBarSeries }]);
    setBarOption({
      ...barOption,
      xaxis: { ...barOption.xaxis, categories: createCategories() },
    });
  };

  {
    /**
        @function : setGraphData
        @functionality : set the graphn data in the local state
    */
  }

  const setGraphData = (tracker_data) => {
    let day_count =
      (tracker_data &&
        tracker_data.day &&
        tracker_data.day.length &&
        tracker_data.day[0].target_achieved &&
        parseInt(tracker_data.day[0].target_achieved)) ||
      0;

    let week_count =
      (tracker_data &&
        tracker_data.week &&
        tracker_data.week.length &&
        tracker_data.week[0].target_achieved &&
        parseInt(tracker_data.week[0].target_achieved)) ||
      0;

    let month_count =
      (tracker_data &&
        tracker_data.month &&
        tracker_data.month.length &&
        tracker_data.month[0].target_achieved &&
        parseInt(tracker_data.month[0].target_achieved)) ||
      0;

    let quarter_count =
      (tracker_data &&
        tracker_data.quarter &&
        tracker_data.quarter.length &&
        tracker_data.quarter[0].target_achieved &&
        parseInt(tracker_data.quarter[0].target_achieved)) ||
      0;

    let halfYearly_count =
      (tracker_data &&
        tracker_data.half_yearly &&
        tracker_data.half_yearly.length &&
        tracker_data.half_yearly[0].target_achieved &&
        parseInt(tracker_data.half_yearly[0].target_achieved)) ||
      0;

    let yearly_count =
      (tracker_data &&
        tracker_data.yearly &&
        tracker_data.yearly.length &&
        tracker_data.yearly[0].target_achieved &&
        parseInt(tracker_data.yearly[0].target_achieved)) ||
      0;

    setRadialData(tracker_data);

    setRadialSeries([
      day_count,
      week_count,
      month_count,
      quarter_count,
      halfYearly_count,
      yearly_count,
    ]);
  };

  let optionBarGraph = {
    ...barOption,
    xaxis: { ...barOption.xaxis, categories: createCategories(currentMonth) },
  };

  return (
    <div className="cus-main-container">
      <div className="cus-inner-container">
        <div className="full-width">
          <div className="page-heading">
            <h2>Target Tracking</h2>
          </div>
        </div>
        <div className="cus-date-sec">
          <h3>{moment().format("DD, MMMM, YYYY")}</h3>
        </div>
        <div className="full-width cus-middle-sec">
          <div class="card">
            <div className="half-width left-card">
              <div className="half-width">
                <div className="cus-per-sec">
                  <div class="info">
                    <div class="row week dot-1">
                      <Typography variant="h3" className="pl-5">
                        <span id="dayValue">
                          {(radialSeries && radialSeries[0]) || 0}
                        </span>
                        % Day
                      </Typography>
                    </div>
                    <div class="row month dot-2">
                      <Typography variant="h3" className="pl-5">
                        <span id="weekValue">
                          {(radialSeries && radialSeries[1]) || 0}
                        </span>
                        % Week
                      </Typography>
                    </div>
                    <div class="row quarter dot-3">
                      <Typography variant="h3" className="pl-5">
                        <span id="monthValue"> {radialSeries[2] || 0}</span>%
                        Month
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div className="half-width">
                <WhiteCircleChart
                  daySeriesValue={radialSeries[0] || 0}
                  weekSeriesValue={radialSeries[1] || 0}
                  monthSeriesValue={radialSeries[2] || 0}
                />
              </div>
            </div>
            <div className="half-width right-card">
              <div className="half-width">
                <WhiteCircleSecondChart
                  quarterSeriesValue={radialSeries[3] || 0}
                  halfYearlySeriesValue={radialSeries[4] || 0}
                  yearlySeriesValue={radialSeries[5] || 0}
                />
              </div>
              <div className="half-width">
                <div className="cus-per-sec">
                  <div class="info">
                    <div class="row week dot-1">
                      <Typography variant="h3" className="pl-5">
                        <span id="quarterValue">{radialSeries[3] || 0}</span>%
                        Quarter
                      </Typography>
                    </div>
                    <div class="row month dot-2">
                      <Typography variant="h3" className="pl-5">
                        <span id="halfYearlyValue">{radialSeries[4] || 0}</span>
                        % Half Year
                      </Typography>
                    </div>
                    <div class="row quarter dot-3">
                      <Typography variant="h3" className="pl-5">
                        <span id="completeYearlyValue">
                          {" "}
                          {radialSeries[5] || 0}
                        </span>
                        % Yearly
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="full-width">
          <div className="cus-bar-chart">
            <Chart
              options={optionBarGraph}
              series={barSeries}
              type="bar"
              height={`${window.innerWidth > 1440 ? 250 : 155}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
