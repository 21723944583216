import axios from 'axios'
import qs from 'querystring'
import { apiURL } from '../constant'
import { getObject, logOut } from './util'

export function generateUrl(path) {
    if (path.includes('http')) return path
    return apiURL + path
}

const axiosParams = {
    endPoint: '',
    data: '',
    method: 'get',
    headers: '',
    requestOptions: '',
    config: '',
}

export function apiReq(axiosParams) {
    let { endPoint, data, method, headers, requestOptions = {}, config = {} } = axiosParams
    return new Promise((res, rej) => {
        headers = { ...getHeaders(), ...headers }
        if (method === "get" || method === "delete") {
            data = {
                ...requestOptions,
                params: { ...data },
                paramsSerializer: function (params) {
                    return qs.stringify(params);
                },
                headers
            }
        }

        let updateData = data instanceof FormData ? data : { ...data }

        axios.defaults.timeout = 60000;
        axios[method](endPoint, updateData, { headers, ...config })
            .then((result) => {
                let { data, status } = result;
                if (status === 200) return res(data);
                return rej(data)
            })
            .catch((err) => {
                if (err.response && err.response.status === 401 && !endPoint.includes('/login')) {
                    logOut('')
                    window.location.href = '/login'
                }
                if (
                    err
                    && err.response
                    && err.response.data
                    && err.response.data.message == 'Authorization token expired!'
                ) {
                    logOut('')
                    window.location.href = '/login'
                }
                const errData = err && err.response && err.response.data || {};
                return rej(errData)
            })
    })
}

export function apiPost({ endPoint, data, headers = {}, config = {} }) {
    return apiReq({ endPoint: generateUrl(endPoint), data, method: "post", headers, config });
}

export function apiDelete({ endPoint, data, headers = {} }) {
    return apiReq({ endPoint: generateUrl(endPoint), data, method: "delete", headers });
}

export function apiGet({ endPoint, data, headers = {}, requestOptions }) {
    return apiReq({ endPoint: generateUrl(endPoint), data, method: "get", headers, requestOptions });
}

export function apiPut({ endPoint, data, headers = {} }) {
    return apiReq({ endPoint: generateUrl(endPoint), data, method: "put", headers });
}

export function apiPatch({ endPoint, data, headers = {} }) {
    return apiReq({ endPoint: generateUrl(endPoint), data, method: "patch", headers });
}

export function getHeaders() {
    let user = getObject('') || {}
    let token = user && Object.keys(user).length && JSON.parse(user).access_token
    return { Authorization: `Bearer ${token || null}` };
}