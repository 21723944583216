import { getDateDataAPI } from "../Api/datepage";

export function getDateData() {
    return new Promise((resolve, reject) => {
        getDateDataAPI().then(res => {
            return resolve(res);
        }).catch(err => {
            return reject(err);
        })
    })
}