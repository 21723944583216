import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './Redux/configureStore';
import Routes from './Routes';
import './Assets/styles/index.scss'

const theme = createTheme({

})

const store = configureStore({});

// if (module.hot) {
//   module.hot.accept();
// }

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store} >
      <Routes />
    </Provider>
  </ThemeProvider>
  ,
  document.getElementById('root')
);

