
export function getObject(key) {
    if (window && window.localStorage) {
        return window.localStorage.getItem(key)
    }
}

export function logOut(userType) {
    return new Promise((res, rej) => {
        localStorage.removeItem(userType);
        res(true)
    })
}

export function isLoggedIn(name) {
    const access_token = window.localStorage.getItem(name)
    return access_token;
}