import React from 'react'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import DatePage from '../Container/DatePage/index'
import HomePage from '../Container/HomePage';
import PublicRoute from './PublicRoute'


function Routes() {
    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/date" component={DatePage} />
                </Switch>
            </BrowserRouter>
        </React.Fragment>
    )
}

export default Routes