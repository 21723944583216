import React from "react"

export const radialChartOptions = {
    chart: {
        height: 350,
        // type: 'line',
    },
    plotOptions: {
        radialBar: {
            inverseOrder: false,
            startAngle: 0,
            endAngle: 360,
            offsetX: 0,
            offsetY: 0,
            track: {
                show: true,
                startAngle: 2,
                endAngle: 2,
                background: '#f2f2f2',
                strokeWidth: '67%',
                opacity: 1,
                margin: 0,
                dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.5
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#ABE5A1'],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                }
            },
            stroke: {
                // show: true,
                // curve: 'round',
                lineCap: 'round',
                // colors: undefined,
                width: 8,
                dashArray: 0,
            },
            dataLabels: {
                show: false,
                name: {
                    fontSize: '22px',
                },
                value: {
                    fontSize: '16px',
                },
            }
        }
    },
    colors: ['#00A88E', '#5BE4B5', '#C8FAE0'],
    labels: ['A', 'B', 'C', 'D']
}



export const barChartOption = {
    chart: {
        height: 350,
        toolbar: {
            show: false,
        },
    },
    grid: {
        show: false,
        xaxis: {
            lines: {
                show: false  //or just here to disable only x axis grids
            }
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '90%',
            endingShape: 'flat',
            borderRadius: 2,
            dataLabels: {
                show: false,
                position: 'top', // top, center, bottom
            },
        }
    },
    fill: {
        // type: 'gradient',
        opacity: 1,
        gradient: {
            type: "vertical",
            // colorFrom: '#C8FAE0',
            // colorTo: '#5BE4B5',
            // stops: [0, 100],/
            // opacityFrom: 0.5,
            // opacityTo: 0.4,
        }
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        categories: [],
        position: 'bottom',
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
        tooltip: {
            enabled: false,
        }
    },
    yaxis: {
        show: false,
        // title: {
        //     offsetX: -10,
        //     style: {
        //         fontSize: '18px',
        //         fontWeight: 'bold',
        //         fontFamily: undefined,
        //         color: '#868686'
        //     }
        // },
        labels: {
            style: {
                colors: '#ffffff'
            }
        },
    },
    axisTicks: {
        show: false,
    },
    colors: ['#00A88E']
}


export const barChartOptionBlack = {
    chart: {
        height: 350,
        toolbar: {
            show: false,
        },
    },
    grid: {
        show: false,
        xaxis: {
            lines: {
                show: false  //or just here to disable only x axis grids
            }
        }
    },
    plotOptions: {
        bar: {
            columnWidth: '90%',
            endingShape: 'flat',
            borderRadius: 2,
            dataLabels: {
                show: false,
                position: 'top', // top, center, bottom
            },
        }
    },
    fill: {
        // type: 'gradient',
        opacity: 1,
        gradient: {
            type: "vertical",
            // colorFrom: '#C8FAE0',
            // colorTo: '#5BE4B5',
            // stops: [0, 100],/
            // opacityFrom: 0.5,
            // opacityTo: 0.4,
        }
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        categories: [],
        position: 'bottom',
        axisBorder: {
            show: false
        },
        axisTicks: {
            show: false
        },
        tooltip: {
            enabled: false,
        },
        labels:{
            style:{
                colors : "#fff"
            }
        }
    },
    yaxis: {
        show: false,
        // title: {
        //     offsetX: -10,
        //     style: {
        //         fontSize: '18px',
        //         fontWeight: 'bold',
        //         fontFamily: undefined,
        //         color: '#868686'
        //     }
        // },
        labels: {
            style: {
                colors: '#ffffff'
            }
        },
    },
    axisTicks: {
        show: false,
    },
    colors: ['#fff']
}