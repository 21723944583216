

//White theme chart file

import { useEffect } from "react";
import { Typography } from "@material-ui/core";
import Logo from "../../Assets/images/circle-logo.svg"

const circleNames = ["quarter", "halfYearly", "completeYearly"];

export default function WhiteCircleSecondChart(props) {

    const circleNames = ["quarter", "halfYearly", "completeYearly"];
    const { quarterSeriesValue = 0, halfYearlySeriesValue = 0, yearlySeriesValue = 0 } = props;
    const circles = {};
    const controllers = document.querySelectorAll(".controller");

    function setProgress(circle) {
        if (circle) {
            const percent = circle.value / circle.limit * 100;

            if (percent <= 100) {
                const offset = circle.length - percent / 100 * circle.length;
                if (circle.circle)
                    circle.circle.style.strokeDashoffset = offset;
            } else {
                if (circle.circle)
                    circle.circle.style.strokeDashoffset = 0;
            }

            if (circle.dot)
                circle.dot.style.transform = `rotate(${percent * 3.6}deg)`;
        }
    }

    circleNames && circleNames.forEach(circleName => {
        const circle = document.querySelector(`#${circleName} .progress-ring__circle`);
        const dot = document.querySelector(`#${circleName} .dot`);
        const length = circle && circle.getTotalLength();
        let limit = 0;
        let value = 0;

        if (circleName === "quarter") { limit = 100; value = quarterSeriesValue };
        if (circleName === "halfYearly") { limit = 100; value = halfYearlySeriesValue };
        if (circleName === "completeYearly") { limit = 100; value = yearlySeriesValue };

        circles[circleName] = {
            circle,
            dot,
            length,
            limit,
            value
        }

        if (circle) {
            circle.style.strokeDashoffset = length;
            circle.style.strokeDasharray = `${length} ${length}`;

            document.querySelector(`#${circleName}Value`).textContent = circles[circleName].value;
        }

        setProgress(circles[circleName]);
    })

    controllers.forEach(controller => {
        controller.addEventListener("change", e => {
            const value = e.target.value;
            const target = e.target.dataset.target;

            circles[target].value = value;
            setProgress(circles[target]);
            document.querySelector(`#${target}Value`).textContent = circles[target].value;
        })

    })


    return (
        <>
            <div className="circles-container">
                <div class={`quarter ring ${quarterSeriesValue < 100 ? 'single-arrow' : 'double-arrow'}`} id="quarter">
                    <div class="dot"></div>
                    <svg class="progress-ring" height={`${window.innerWidth > 1440 ? 360 : window.innerWidth < 1200 ? 220 : 280}`} width={`${window.innerWidth > 1440 ? 360 : window.innerWidth < 1200 ? 220 : 280}`}>
                        <linearGradient id="aGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stop-color="#199883" />
                            <stop offset="100%" stop-color="#199883" />
                        </linearGradient>
                        <circle class="progress-ring__circle"
                            stroke-width="32" stroke-linecap="round" stroke="url(#aGradient)"
                            fill="transparent"
                            r={`${window.innerWidth > 1440 ? 164 : window.innerWidth < 1200 ? 94 : 124}`} cx={`${window.innerWidth > 1440 ? 180 : window.innerWidth < 1200 ? 110 : 140}`} cy={`${window.innerWidth > 1440 ? 180 : window.innerWidth < 1200 ? 110 : 140}`} />
                    </svg>
                </div>
                <div class={`halfYearly ring ${halfYearlySeriesValue < 100 ? 'single-arrow' : 'double-arrow'}`} id="halfYearly">
                    <div class="dot"></div>
                    <svg class="progress-ring" height={`${window.innerWidth > 1440 ? 300 : window.innerWidth < 1200 ? 160 : 220}`} width={`${window.innerWidth > 1440 ? 300 : window.innerWidth < 1200 ? 160 : 220}`}>
                        <linearGradient id="eGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stop-color="#7CE495" />
                            <stop offset="100%" stop-color="#7CE495" />
                        </linearGradient>
                        <circle class="progress-ring__circle"
                            stroke-width="32" stroke-linecap="round" stroke="url(#eGradient)"
                            fill="transparent"
                            r={`${window.innerWidth > 1440 ? 134 : window.innerWidth < 1200 ? 64 : 94}`} cx={`${window.innerWidth > 1440 ? 150 : window.innerWidth < 1200 ? 80 : 110}`} cy={`${window.innerWidth > 1440 ? 150 : window.innerWidth < 1200 ? 80 : 110}`} />
                    </svg>
                </div>
                <div class={`completeYearly ring ${yearlySeriesValue < 100 ? 'single-arrow' : 'double-arrow'}`} id="completeYearly">
                    <div class="dot"></div>
                    <svg class="progress-ring" height={`${window.innerWidth > 1440 ? 240 : window.innerWidth < 1200 ? 100 : 160}`} width={`${window.innerWidth > 1440 ? 240 : window.innerWidth < 1200 ? 100 : 160}`}>
                        <linearGradient id="sGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stop-color="#00C26E" />
                            <stop offset="100%" stop-color="#00C26E" />
                        </linearGradient>
                        <circle class="progress-ring__circle"
                            stroke-width="32" stroke-linecap="round" stroke="url(#sGradient)"
                            fill="transparent"
                            r={`${window.innerWidth > 1440 ? 104 : window.innerWidth < 1200 ? 34 : 64}`} cx={`${window.innerWidth > 1440 ? 120 : window.innerWidth < 1200 ? 50 : 80}`} cy={`${window.innerWidth > 1440 ? 120 : window.innerWidth < 1200 ? 50 : 80}`}
                        />
                    </svg>
                </div>
                <div className="cus-logo">
                    <img src={Logo} alt="Logo" />
                </div>
            </div>
        </>
    );
}